import React from "react";
import "./HeroCenter.css";
import topvideo from "../../assets/topVideo.mp4";
import JoinWaitList from "../JoinWaitList/JoinWaitList";
import { color } from "framer-motion";

const HeroCenter = () => {
  return (
    <div className="heroCenter">
      <div className="heroCenterHeading">
        Unlock Limitless <span>Untapped</span>
        <br /> Liquidity In DeFi
      </div>
      <div className="heroContent">
      Xythum lets you access the hidden reserves of DeFi with an experience of unmatched liquidity, privacy, and efficiency across chains—driving the future of decentralized finance.
      </div>
      <div className="joinWaitList">{/* <JoinWaitList/> */}</div>
      <div className="lightImage">
        <video width="80%" height="auto" autoPlay muted loop playsInline preload="auto" >
          <source src={topvideo} type="video/mp4"></source>
        </video>
        <span id='learn'></span>
      </div>
    </div>
  );
};

export default HeroCenter;
