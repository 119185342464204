import React from 'react'
import SwapSec from '../components/swap/SwapSec'

const Swap = () => {
  return (
    <div>
      <SwapSec />
    </div>
  )
}

export default Swap
