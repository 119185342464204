import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import Navbar from '../navbar/Navbar'
import HeroCenter from '../HeroCenter/HeroCenter'
import { Description } from '../description/Description'
import DarkPool from '../darkPool/DarkPool'
import TechStack from '../TechStack/TechStack'
import Roadmap from '../Roadmap/Roadmap'
import Mailing from '../Mailing/Mailing'
import Footer from '../Footer/Footer'
import Blog from '../Blog/Blog'
import FAQ from '../FAQS/FAQ'
import { Sequence } from '../SequenceDiagram/Sequence';
import Challange from '../Challange/Challenge';

const Hero = () => {

  return (
    <div>
      <Navbar />
      <HeroCenter  />
      <Description />
      <DarkPool  />
      <Challange/>
      <TechStack/>
      <Sequence/>
      <Roadmap/>
      <FAQ/>
      {/* <Blog data-aos="fade-left" /> */}
      <Mailing/>
      <Footer />
    </div>
  );
};

export default Hero;
